import React, { Component, ChangeEvent } from 'react';
import videos from './Videos';
import {BrowserRouter as Router,Switch,Route,Link} from 'react-router-dom'

interface IProps {
    loggedIn: boolean
}

interface IState {

}

class PatchNotes extends Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);
    }
    render = () => {

        const demo0_18_8_video = function () {
            return {
                __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/QUV3q-qR1Vw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        };

        const demo1_video = function () {
            return {
                __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/T-KTEqjJoSE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        };

        const demo2_video = function () {
            return {
                __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/3J3hjhQ0AQ4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        };

        const demo3_video = function () {
            return {
                __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/C8nUy4K-W9k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        };

        const guide_gather_blackspots_1_video = function () {
            return {
                __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/LtChlj5h7Cs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        };

        return (
            <div>
                {this.props.loggedIn &&
                    <div>
                        <h1>Patch Notes </h1>   
                        <div>
                        <h3>1.20.0 - 2024/10/24</h3>
                        <ul>
                            <li>Support for 11.0.5!</li>
                            <li>Slight improvements to flying gather navigation speed.</li>
                        </ul>
                            <h3>1.19.0 - 2024/10/09</h3>
                            <ul>
                                <h4>Flying gather bot improvements</h4>
                                <li>Support mining while mounted if the player has mining specialization allowing gathering while mounted.</li>
                                <li>Propper support for "CompletelyIgnoreMobsWhileMounted" to allow gathering nodes without needing to fight mobs which attack the player.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                            <img style={{ maxWidth: "800px" }} src="https://i.imgur.com/YvlRv55.gif"></img>
                                </div>
                                <li>New settings section specifically for gather flying bot - previously shared settings from ground gather.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                            <img style={{ maxWidth: "800px" }} src="https://imgur.com/r06AlYr.png"></img>
                                </div>
                                <li>Faster detection of nodes which are unreachable - should substantially speed up navigation around complex geometry.</li>
                                <li>Better detection of underground nodes and automatic blacklisting when multiple attempts to generate path to them has failed.</li>
                                <li>Bug fix - If the player is on the ground and the node is unreachable via ground or flying navigation it will now correctly blacklist the node and continue.</li>
                                <li>Bug fix - If the player is directly above the node but for some reason the bot cannot generate a path it will now simply land directly below to gather.</li>
                                <li>Bug fix - If the player enters a cave to gather but the node despawns while navigating to it the bot will not attempt to exit cave instead of just getting stuck in an infinite loop.</li>
                                <h4>General</h4>
                                <li>Blackspot editor improvement - now possible to add blackspots for nodes by double clicking on them in the list. Useful for blacklisting underground nodes without having to go directly to them.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                            <img style={{ maxWidth: "800px" }} src="https://imgur.com/3je8NT5.gif"></img>
                                </div>
                                

                            </ul>
                            <h3>1.16.0 - 2024/06/25</h3>
                            <ul>
                                <li>Gather flying bot now supports return to corpse using ghost flying. Bot will detect if in a flying ghost form and attempt to generate a flying route back to the corpse. In the event it is unable to generate a path it will fallback to just using spirit healer.</li>
                                <li>Reduced gathering times for ground + flying gather bot - removed around 800ms of delay between actions - looks a lot smoother now</li>
                                <li>Interact with npc logic now has a built in retry system in case of server lag where gossip frame does not appear - should help reduce crashes.</li>
                            </ul>
                            <h3>1.14.5 - 2024/05/14</h3>
                            <ul>
                                <li>Fixed bug with chain profile path generation not selecting the supplied paths from all available profiles.</li>
                                <li>Mesh generation now uses improved server side caching of meshes to fix rare problem with bot crashing when first starting and downloading mesh.</li>
                                <li>Fixed bug with reading classic and Cata auras when the play had more than 32 auras.</li>
                                <li>Chain profile bot now allows flying gather routes which can cleaverly be used for flying navigation across continents.</li>
                                <li>new combat routine condition 'Distance From World Position' which can be used in chain profile bot to allow moving to next step when reached a certain destination, e.g. used with gather flying route to move across continent.</li>
                            </ul>
                            <h3>1.14.0 - 2024/05/01</h3>
                            <ul>
                                <li>Cata classic support</li>                                
                            </ul>
                            <h3>1.13.0 - 2024/03/22</h3>
                            <ul>
                                <li>Always use newer path generation logic when moving from graveyard back to main route.</li>
                                <li>When bags cannot be emptied e.g. "Unable to empty bags" it now throws exception instead of just stopping bot so that monitor application can detect crash and restart.</li>
                                <li>Improved "stuck in combat" logic to now consider mobs which are evading or on the blacklist. This helps in situation where those mobs were keeping player in combat, making the bot stand still till death or disconnect.</li>
                                <li>Restarting the bot while dead in between graveyard and corpse no longer causes it to crash. Bot now always knows of the corpse position, even if across the map.</li>
                                <li>AH bot now has failsafe for when nothing is looted after 2 minutes and sitting waiting for mail to open.</li>
                                <li>Better detection of when mob is evading or underground, bot will now correctly ignore mob and detect if its keeping it in combat to continue along profile.</li>
                            </ul>
                            <h3>1.12.1 - 2024/02/02</h3>
                            <ul>
                                <li>New option 'AllowPartialMatching' for vendor config. When enabled, it will allow partial matches - e.g. 'Leather' will match 'Light Leather', 'Heavy Leather' etc.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                            <img style={{ maxWidth: "800px" }} src="https://imgur.com/kZC8ZGQ.png"></img>
                                </div>
                            </ul>
                        
                            <h3>1.12.0 - 2024/02/01</h3>
                            <ul>
                                <li>Open beta release of Chain Profile, Travel and Task bot! A few of you have already been using these bot types for a while now and it's about time I opened up access to all existing full subscribers. And it goes without saying , all feedback, bug reports and feature requests are greatly appreciated. Thank you for all the support in 2023. Happy botting and 2024!</li>
                                <li>New <a href="/guides">Chain profile</a> guides added to site.</li>
                                <li>Example chain profile, tasks and travel profiles added. also included is a simple classic SOD warlock leveling CR that I have used with this chain profile for testing.</li>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_timelapse)} />
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_1)} />
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_2)} />
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_3)} />
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/BA7byeH.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/wWqFdU9.png"></img>
                                </div>
                                
                            </ul>
                            <h3>1.11.0 - 2024/01/31</h3>
                            <ul>
                                <li>New setting : 'IgnoreSkinningMobsTappedByOtherPlayerInXSeconds' - Default blank. If the bot should ignore skinning mobs tapped by other players for X number of seconds. This can help prevent 'ninja' skinning of mobs.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/2gbIz7A.png"></img>
                                </div>
                            </ul>
                            <h3>1.10.0 - 2024/01/27</h3>
                            <ul>
                                <li>New settings : 'ForceUseSpiritHealerAfterNumberOfDeaths' + 'ForceUseSpiritHealerTimeWindowSeconds' which allow falling back to using spirit healer if the bot keeps dying while trying to res at corpse.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/hgCHdjL.png"></img>
                                </div>
                                <li>New option in vendor configuration for 'DO NOT SELL'. this will invert the behaviour so that those defined items are kept.</li>
                                <li>New option in vendor configuration for item rarity 'All'.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/eSRHigd.png"></img>
                                </div>
                            </ul>
                            <h3>1.9.7 - 2024/01/19</h3>
                            <ul>
                            <li>New settings IgnoreLootOrSkinsWithHostileMobsNearby , Count and Range. When enabled will ignore looting and skinning when mobs are nearby the corpse based on settings IgnoreLootOrSkinsWithMobsNearbyCount and IgnoreLootOrSkinsWithMobsNearbyDistance values. Disabled by default.</li>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/14VXxQT.png"></img>
                                </div>
                            
                                <li>New settings MailGold and MailGoldMinimumCopperRemainingInBags. This allows the bot to send gold as part of its mailing actions. note MailItems also needs to be enabled for this to do anything. If MailGold is enabled, MailGoldMinimumCopperRemainingInBags is the MINIMUM amount of copper that should be remaining in bags after all gold is sent. 10000 copper = 1 gold . generally you want this to be something sensible so that your characters have enough gold remaining for food/repair etc.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/5mFCBOq.png"></img>
                                </div>
                                <li>New ON_UNEXPECTED_APP_EXIT_WHILE_BOT_RUNNING event which can be toggled on for discord notifications in bot settings. This will fire if the bot process completely crashes unexpectedly and has a brief moment to attempt to send a notification. It will not catch all cases, this is just a best effort attempt made to send the discord notification before the bot process is completely killed.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/4EUJfnY.png"></img>
                                </div>
                                <li>Fixed issue when mailing items and gold to characters on other battle.net accounts. It will now click the confirmation dialog that appears.</li>
                                <li>Fixed issue which was causing bot to keep running even after closing the program. The process will now correctly exit once the main bot window is closed. This also fixes some issues with closing and starting the bot multiple times and attaching to same wow instance.</li>
                                
                            </ul>
                            <h3>1.9.3 - 2023/11/24</h3>
                            <ul>
                                <li>New BOT_EVENT_ON_STOP event which can be toggled on for sound and discord notifications in bot settings.</li>
                            </ul>
                            <h3>1.9.2 - 2023/11/10</h3>
                            <ul>
                            <li>New gather configuration options 'IgnoreSkinningWithPlayersNearby' and 'IgnoreSkinningWithPlayersNearbyDistance' - these work exactly the same as the gather node settings. prevent 'ninja' skinning mobs that other players have killed or are close to. If the player has recently attacked the creature it will ignore this setting, prevents other players 'ninja' your kills.</li>
                            </ul>
                            <h3>1.9.1 - 2023/11/09</h3>
                            <ul>
                            <li>Fixed bug in classic wotlk where bot would not recognise 'can't use that here' error messages when attempting to mount up in dalaran - causing the bot to keep trying to mount up on flying mounts over and over.</li>
                            <li>Flying gather bot will now first try and navigate along the ground to the next point along a waypoint path if its unable to mount up or fly to the location.</li>
                            <li>Navigation mesh support for new dragonflight emerald dream zone.</li>
                            </ul>
                            
                            <h3>1.9.0 - 2023/11/06</h3>
                            <ul>
                                <li>Fixed bug with ground navigation where sometimes bot would completely freeze when generating very long paths - across a continent for example. This also should fix some exceptional cases where bot is unable to generate a path close to required destination.</li>
                                <li>Improved detection of successful gathering attempts in WOTLK classic - this helps to reduce the number of times the bot attempts to gather a node again after successful harvest.</li>
                                <li>New combat routine condition 'Number Of Creatures In Range'</li>
                                <li>All "Number of creatures" type conditions now allow specifying a set of conditions that must match against in order to be considered part of the check. This applies to 'Number Of Creatures In Range', 'Number Of Hostile Creatures In Range', 'Number Of Creature Names In Range', 'Number Of Lootable Mobs In Range' and 'Number of Harvestable Mobs In Range'.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/nKMtjV7.png"></img>
                                </div>
                            </ul>
                            <h3>1.8.1 - 2023/10/03</h3>
                            <ul>
                                <li>Frozen keys will now appear as blue in the key selection window.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/H0I8Thy.png"></img>
                                </div>
                            </ul>
                            <h3>1.8.0 - 2023/10/01</h3>
                            <ul>
                                <li>Bot back online for everyone to login. Please contact me on discord for your keys to be unfrozen. After 14 days of running 6 fresh accounts using a mix of grind , gather in both classic WOTLK and retail there is no conclusive evidence of any type of detection. Also to note these 6 fresh accounts survived the 'retail ban wave' that some users reported.</li>
                                <li>Nevertheless I have still made various changes to areas that blizzard would be potentially scanning for in the latest build 1.8.0 - so be sure to update all your bots to this latest version asap.</li>
                                
                            </ul>
                            <h3>1.7.3 - 2023/09/17</h3>
                            <ul>
                                <li>Nav mesh for new zone : Zaralek dragonflight</li>
                                <li>Fixed bug with stationary grind bot pull route from resetting between chain profile swaps causing bot to crash sometimes with "Optional Unsafe" error.</li>
                            </ul>
                            <h3>1.7.0 - 2023/08/25</h3>
                            <ul>
                                <li>With the big interest in classic hardcore - added support for classic client again. 1.14.x. It has been almost 2 years since the last time the bot was updated for classic era so please report any issues. Good luck and have fun!</li>
                            </ul>
                            <h3>1.6.0 - 2023/08/10</h3>
                            <ul>
                                <li>New discord notification system for alerts on bot crash, whisper etc. <a href="/guides">See 'Discord Notification Setup' section on guides page</a> </li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/jch8HzV.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/HdYMzIy.png"></img>
                                </div>
                                
                                
                            </ul>
                            <h3>1.5.1 - 2023/08/06</h3>
                            <ul>
                                <li>New Combat routine condition type : "Number Of Creature Names In Range" - for checking for a list of creature names in range of player or target. Good for only executing actions when near friendly party members.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/jpRXB8h.png"></img>
                                </div>
                                <li>New combat routine action : "Force Exit Wow" - entirely closes wow game client - this will obviously cause the bot to crash but intended to be used to stop bot on some special set of conditions defined in combat routine.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/HAVt4Oc.png"></img>
                                </div>
                            </ul>

                            
                            <h3>1.5.0 - 2023/07/28</h3>
                            <ul>
                                <li>Reduced nav mesh memory and cpu usage - especially when building large connected graphs in chain profile bot.</li>
                                <li>New debug option 'Signal Return To Town' - this will simulate a 'bags full' event , making the bot run the 'return to town' routine. Useful for when testing out new profiles or just ending a botting session.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/lpvWCfs.png"></img>
                                </div>
                                <li>New "Bot Context" tab in debug controls - This tab presents the active loaded state of the bot and key information for diagnosing issues that are reported by users.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/vtpr15n.png"></img>
                                </div>
                            </ul>
                            <h3>1.4.6 - 2023/07/03</h3>
                            <ul>
                                <li>Improvement to Grind, Ground Gather and Stationary Grind : When moving around main route bot will now interrupt navigation to ensure that all buffs are active instead of only checking for buffs once click to move action is complete. This new behavior and the addition of new 'CanExecuteWhileMoving' option on combat routine actions allows for use of movement skills out of combat if set up correctly.</li>
                                <li>New combat routine option : 'Run buff sequence while in combat' - If the buff sequence should run even when in combat. Enabling this option can help if you want to avoid having to define buffs for both in and out of combat twice.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/tzgYF0w.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/2kQ4GkI.png"></img>
                                </div>
                            </ul>
                            <h3>1.4.1 - 2023/06/26</h3>
                            <ul>
                                <li>Ground gather bot now uses more intelligent logic for determining which waypoint to return to after gathering a node. Will no longer just pick the closest waypoint to the player but also considers if the waypoint would skip large parts of the route. The logic is not bullet proof but fixes majority of cases where was likely to skip big loops of a path when two routes intersect - e.g. a figure 8 path.</li>
                            </ul>
                            <h3>1.4.0 - 2023/06/21</h3>
                            <ul>
                                <li>Support for WOTLK call of the crusade patch 3.4.2-50129</li>
                            </ul>
                            <h3>1.3.1 - 1.3.10 - 2023/06/20</h3>
                            <ul>
                                <li>Various minor bug fixes reported by users over last month - mainly with stationary grind and new chain profile bot.</li>
                                <li>If you would like beta access to the chain profile bot please contact me directly on discord. A few users have been using it for last 2 months and feedback has been really useful - please continue.</li>
                            </ul>
                            <h3>1.3.0 - 2023/05/25</h3>
                            <ul>
                                <li>Fixed bug with combat routine condition editor when using copy feature the copied properties would share the same underlying values resulting in the user modifying both the source and copied properties unexpectedly.</li>
                                <li>New combat routine condition : 'Nested Condition' - Allows for easier combining and building of advanced conditions.</li>
                                <li>New combat routine condition : 'Max Profession Skill Level' - Check for a maximum profession skill level of the player.</li>
                                <li>New combat routine condition : 'Current Profession Skill Level' - Check for a current profession skill level of the player.</li>
                                <li>New combat routine condition : 'Player Knows Spell Id' - Check if the player knows or does not know a specific spell Id.</li>
                                <li>New combat routine condition : 'Player Knows Spell Name' - Check if the player knows or does not know a specific spell name.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/qsAQwxu.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/FmEL6w7.png"></img>
                                </div>

                                
                            </ul>
                            <h3>1.2.0 - 2023/05/05</h3>
                            <ul>
                                <li>Support for patch dragonflight 10.1.0</li>
                                <li>More precise line of sight checks for spell casting and flying navigation - now based on actual model collision boxes.</li>
                            </ul>
                            <h3>1.1.7 - 2023/04/21</h3>
                            <ul>
                                <li>Fixed bug with druid shapshift forms failing to load on low level characters in WOTLK client.</li>
                            </ul>
                            <h3>1.1.6 - 2023/04/03</h3>
                            <ul>
                                <li>Bot will now wait until send mail frame is open when interacting with mailbox - this is to help prevent situations where there is lag opening the send mail frame and bot attempts to send items before the send frame window is shown.</li>
                            </ul>
                            <h3>1.1.4 - 2023/03/25</h3>
                            <ul>
                                <li>Fixed bug in retail where gossip option selection was broken due to blizzard lua api changes.</li>
                            </ul>
                            <h3>1.1.3 - 2023/03/22</h3>
                            <ul>
                                <li>Support for 10.0.7</li>
                                <li>Aura debug window now shows 'Hidden' auras not usually shown in game. This can be used to detect passive skills or special buffs normally impossible to detect. Opens potential for more complex combat routines detecting if passives are present etc.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/TiDJEn2.png"></img>
                                </div>
                            </ul>
                            <h3>1.1.2 - 2023/03/14</h3>
                            <ul>
                                <li>Fixed bug in classic + retail where bot would disconnect character when trying to 'face target' when player had no target.</li>
                            </ul>
                            <h3>1.1.0 - 2023/03/03</h3>
                            <ul>
                                <li>New Combat Routine Condition - Player Totem or Statue Exists.</li>
                                <li>New Combat Routine Action - Destroy Totem or Statue By Name.</li>
                            </ul>
                            <h3>1.0.19 - 2023/02/15</h3>
                            <ul>
                                <li>Fixed lua timeouts for various combat routine actions - these didn't cause crashes but would spam lua errors in wow and use unnecessary bot CPU cycles.</li>
                                <li>Fixed bug with resurrection in classic WOTLK for characters below level 10.</li>
                                <li>Background changes to bot core loading logic that should not result in any behaviour changes. If you notice any new types of crashes when first loading the bot please let me know so I can take a look.</li>
                            </ul>
                            <h3>1.0.17 - 2023/02/01</h3>
                            <ul>
                                <li>Support of latest TSM version.</li>
                            </ul>
                            <h3>1.0.14 - 2023/01/25</h3>
                            <ul>
                                <li>Fixed edge case where bot would fail to attach to wow when other processes are being started at same time like when using monitor to launch multiple bots.</li>
                                <li>When detecting a 'skinning priority mob' it will prioritise this over all other looting / skinning.</li>
                            </ul>
                            <h3>1.0.12 - 2023/01/22</h3>
                            <ul>
                                <li>Fixed bug with bag 5 being detected as unspecified and counting towards free bag slots.</li>
                                <li>Fixed bug with stationary grind bot after exit route not always continuing onto run town actions</li>
                                <li>Interact with vendor routine now checks if the interact was successful before moving onto repairing, buying or selling items and will retry interact if it failed.</li> 
                            </ul>
                            <h3>1.0.10 - 2023/01/22</h3>
                            <ul>
                                <li>Retail support for new reagent bag - bag 5</li>
                                <li>Fixed bug in "swap target" combat routine action to correctly use Condition Evaluation Method defined at the action level.</li>
                            </ul>
                            <h3>1.0.9 - 2023/01/19</h3>
                            <ul>
                                <li>Support for 3.4.1</li>
                                <li>Fixed bug with Item Cooldown combat routine condition causing it to timeout.</li>
                            </ul>
                            <h3>1.0.5 - 2023/01/18</h3>
                            <ul>
                                <li>Added futher diagnostic logging to combat routine to help debug issues.</li>
                            </ul>
                            <h3>1.0.4 - 2023/01/17</h3>
                            <ul>
                                <li>Added additional debug logging for combat routine and skinning routine to help diagnose issues.</li>
                                <li>minor update to support latest dragonflight patch - 10.0.2-47631 </li>
                            </ul>
                            <h3>1.0.3 - 2023/01/14</h3>
                            <ul>
                                <li>Fixes for stationary grind stuck trying to return to kill position over and over and trying to loot/skin out of range mobs and getting stuck.</li>
                            </ul>
                            <h3>1.0.2 - 2023/01/11</h3>
                            <ul>
                                <li>Improved skinning speed for stationary grind bot by roughly x2. Delay between skinning mobs down to 100-200ms instead of 600-800ms</li>
                            </ul>
                            <h3>1.0.0 - 2023/01/10</h3>
                            <ul>
                                <li>New stationary grind bot. <a href="/guides">Full set of video guides available here</a></li>
                                <li>New skinning setting 'IgnoreSkinningMobIds' to prevent bot from skinning certain mobs. Useful if there are grey mobs which are pointless to skin.</li>
                                <li>New skinning setting 'SkinningMobIdPriority' which allows you to add a list of mob ids to prioritize skinning first before anything else. Useful if you want the bot to skin a boss or elite mob before it despawns before anything else is skinned</li>
                                <li>New skinning setting 'SkinningRange' which defaults to 3 but lets you override the maximum range the player can skin from - stopping the bot from walking directly ontop of corpse to skin.</li>
                                <li>New looting setting 'IgnoreLootingMobIds' to prevent bot from looting certain mobs. Useful if there are some mobs which drop useless loot.</li>
                                <li>New looting setting 'LootMobRange' which defaults to 3 but lets you override the maximum range the player can loot from - stopping the bot from walking directly ontop of corpse to loot.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/ZK1CScn.png"></img>
                                </div>
                                <li>New multi pull option for grind bot. <a href="/guides">Video guide available here</a></li>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_multi_pull)} />
                            </ul>
                            <h3>0.99.76 - 2022/12/09</h3>
                            <ul>
                                <li>Dragonflight Evoker combat routine support.</li>
                                <li>New AStar path finding method used for various navigation routines which produces safer routes. return to town , return to corpse etc.</li>
                                <li>Navmesh for profile is generally now loaded at bot start instead of lazy to reduce number of times bot stops to download mesh along route - can help improve performance for users using proxy or vpn.</li>
                            </ul>
                            <h3>0.99.75 - 2022/11/30</h3>
                            <ul>
                                <li>Add support for new dragonflight gathering ore and herbs in settings.</li>
                            </ul>
                            <h3>0.99.73 - 2022/11/28</h3>
                            <ul>
                                <li>Small patch for new dragonflight zone mesh support.</li>
                            </ul>
                            <h3>0.99.72 - 2022/11/20</h3>
                            <ul>
                                <li>Vendor config allows vendoring items by a list of names and ids.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/uDlHXf2.png"></img>
                                </div>
                            </ul>
                            <h3>0.99.71 - 2022/11/16</h3>
                            <ul>
                                <li>Retail : Patch 10.0.2 support.</li>
                            </ul>
                            <h3>0.99.70 - 2022/11/02</h3>
                            <ul>
                                <li>Retail + Classic : Fixed TSM AH bot failing to load.</li>
                                <li>Retail : Fixed monitor/relogger so that it can actually log into game using new UIs</li>
                                <li>Retail : Fixed bug with using spirit healer (new confirmation box)</li>
                                <li>Retail : Fixed line of sight checks sometimes crashing bot</li>
                                <li>Retail : Fixed detection of auras cast by player</li>
                            </ul>
                            <h3>0.99.68 - 2022/10/30</h3>
                            <ul>
                                <li>Fixed TSM AH bot failing to load.</li>
                                <li>Bot no longer crashes when TSM addon is loaded and running grind or gather when opening vendor/mail window.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/5e0YBxN.png"></img>
                                </div>
                            </ul>
                            <h3>0.99.65 - 2022/10/29</h3>
                            <ul>
                                <li>Support for Retail Dragonflight (Prepatch) 10.0.0! Large changes to game client - please report any issues you spot - thank you.</li>
                                <li>AH bot no longer requires combat routine to be loaded.</li>
                            </ul>
                            <h3>0.99.64 - 2022/10/25</h3>
                            <ul>
                                <li>New version 1.9.2 of monitor/relogger. Fixed nearly all cases of 'locked subscription keys' problem - key locks are taken on server side instead of client so no more problems with launching all your clients at the same time across multiple machines.</li>
                                <li>Fixed bug introduced in last patch of monitor where back off durations after retry attempt 20 would default back to only waiting 30 seconds instead of the full 20 minutes.</li>
                                <li>Improved 'escape water' behaviour for gather flying bot introduced in last patch.</li>
                                <li>AH bot - no longer attempts to walk directly onto of auctioneer or vendor - for example will no longer try and walk to auctioneer in orgrimmar and get stuck try to reach him.</li>
                                <li>AH bot - better detection of TSM addon loading - previously the bot would 'Force' open the TSM UI which would cause the Scan to get stuck. This no longer happens as the bot waits for TSM addon to complete loading before interacting with auctioneer</li>
                                <li>Added missing meshes for nazjatar BFA zone.</li>
                                <li>Background changes to bot core to support new chaining profile system. There should be no functional differences to normal bot types - if you notice anything out of the oridinary please let me know.</li>
                            </ul>
                            <h3>0.99.55 - 2022/10/14</h3>
                            <ul>
                                <li>When gather flying bot is stuck inside water and unable to mount it will attempt to find a place on ground to mount and move there.</li>
                                <li>Gather flying bot - better detection and blacklisting of underwater gather nodes.</li>
                                <li>Flying gather bot will no longer blacklist a node because of a player being nearby if an attempt to interact with the node has been made.</li>
                                <li>New version of Monitor app 1.9.0  - a "increasing restart back off" is now added when the bot or game crashes to prevent login spam. The restart back off functions as follows - 30 seconds, 60 seconds , 120 seconds , 240 seconds etc. Up to a maximum of 20 minutes.</li>
                            </ul>
                            <h3>0.99.53 - 2022/10/10</h3>
                            <ul>
                                <li>Fixed bug with ground nav walking into cliff face.</li>
                                <li>Flying gather bot will now select waypoints forward from ore or herb being gathered instead of flying back to where it left the route.</li>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.better_flying_gather_nav)} />
                            </ul>
                            <h3>0.99.43 - 2022/10/05</h3>
                            <ul>
                                <li>Fixed bug in flying gathering bot where player would sometimes run into cliff face infinitely trying to reach nearby node. Bot will now correctly recognise it cannot reach it with ground nav and fly up before coming back down to reach it.</li>
                                <li>Flying gather bot will now run the buff sequence when on the ground in between gathering nodes and running the route.</li>
                            </ul>
                            <h3>0.99.40 - 2022/10/02</h3>
                            <ul>
                                <li>New "Vendor Item Config" for vendoring specific items of rarity AND type. Allows for exmple vendoring of all uncommon armor and weapons but keeping everything else.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/S6yRg1V.png"></img>
                                </div>
                                <li>Two new options in auction house config to enable vendoring before or after the auctioning step. Great for forcing the bot to vendor if an item is below TSM price thresholds or just for vendoring junk gear.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/88OcOuZ.png"></img>
                                </div>
                                <li>New setting inside auction house profile for 'Vendor'. Used as vendor when auction house vendoring is enabled.</li>
                                
                                <li>To learn more about auction house bot see full <a href="/guides">video guides</a>.</li>

                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/OMEYSbM.png"></img>
                                </div>
                            </ul>
                            <h3>0.99.39 - 2022/09/30</h3>
                            <ul>
                                <li>Reduced CPU usage when reading auras from target - will have a bigger impact for combat routines which use a lot of aura checks.</li>
                                <li>Fixed bug with bot not using return to town route when first starting bot.</li>
                                <li>Fixed bot crashing with 'Unable to get spell info for spellid X when running TargetHasAura'</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/4McKTq6.png"></img>
                                </div>
                                <li>New setting DontPullMobsWithPlayersNearby will no longer stop the bot from doing anything when there are players close by and instead just continue along route looking for mobs.</li>
                                <li>New option in blackspot editor to allow setting adding blackspot at location of target. useful if you want to add a blackspot mid air or for a mob that is evading underground for example.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/cvRdmUe.png"></img>
                                </div>
                            </ul>
                            <h3>0.99.38 - 2022/09/29</h3>
                            <ul>         
                            <li>New option to delay returning to corpse for X seconds - useful for pvp servers where camping occurs.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/ia17et5.png"></img>
                                </div>
                            
                                <li>New options to stop grind bot pulling mobs with other players nearby.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/lGwyOxw.png"></img>
                                </div>
                            <li>New option in auction house bot to execute lua on interval - useful for opening items.</li>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/pjXKQ2R.png"></img>
                                </div>
                            
                                <li>New version 1.8.0 of Monitor/Relogger. Includes various fixes around timeouts where 'connection' between monitor and bot would fail + MinRandomDelaySeconds and MaxRandomDelaySeconds now actually works and can be set in the UI.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/3w6qS6V.gif"></img>
                                </div>
                                <li>Added new 'Trace Line Debugging' toggle to make debugging issues with bot core easier when users report issues.</li>
                                <li>Fixed bug with druid flight form failed shapeshift attempts not counting towards failed attempt checks.</li>
                                <li>Fixed bug with druid flight form just sitting above node randomly.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://media.discordapp.net/attachments/627527916896387082/1024696018400452660/Wow_BJeMGDuYet.gif"></img>
                                </div>
                            </ul>
                            <h3>0.99.33 - 2022/09/26</h3>
                            <ul>
                                <li>Good luck for WOTLK launch!</li>
                                <li>Fixed druid shapeshift detection which was causing issues for flight form in WOTLK.</li>
                                <li>New Mount method - "Rouge Stealth". The player will stealth instead of using mount to navigate. Great for avoiding player detection while moving between town and main route. Sneaky &#129323;</li>
                                <li>New Mount method - "Cat Form With Prowl". The player will shapeshift into cat and use prowl instead of using mount to navigate. Great for avoiding player detection while moving between town and main route. Sneaky &#129323;</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/4g9zrBl.png"></img>
                                </div>
                            </ul>
                            
                            <h3>0.99.31 - 2022/09/24</h3>
                            <ul>
                                <li>Reduced bot cpu usage by about 2-3x in most use cases on average.</li>
                                <li>Fixed bug with druid flight form not dismounting correctly in classic - would cause player to get 'stuck' just above surface of ground and unable to fly.</li>
                                <li>Combat routine editor now supports supplying a list of petMobIds instead of just 1 so that its easier to create combat routines that use different pets depending on player level - e.g. warlock imp and voidwalker...</li>
                                <li>New "Special Return To Town Method" for using a spell or item to return to town instead of normal route - <a href={videos.guide_special_return_to_town}>Video Guide</a></li>
                                <li>New setting under combat routine configuration for "MaxTickRate". Most users will want to leave this at default setting 10. If you are trying to run as many bots as possible on a machine and you don't care about doing perfect combat rotation setting this to 1 or 2 will help reduce CPU usage.</li>
                                <li>2 new combat routine conditions : "Number Of Harvestable (Skin, Mine, Herb) Mobs In Range" and "Number Of Lootable Mobs In Range" </li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/EMdaNfh.png"></img>
                                </div>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_special_return_to_town)} />
                                
                                
                            </ul>
                            <h3>0.99.29 - 2022/09/16</h3>
                            <ul>
                                <li>Fixed notification sound playback.</li>
                                <li>More descriptive error message when loading an invalid profile for the bot type selected.</li>
                                <li>Fixed bug in retail gather flying bot with druid getting stuck in ground travel form trying to fly.</li>
                                <li>Background changes for chain profile support coming soon.</li>
                            </ul>
                            <h3>0.99.28 - 2022/09/15</h3>
                            <ul>
                                <li>Matching on spelll aura name is no longer case sensitive</li>
                                <li>Fixed bug with new items being added to bag not being recognized for 1 minute</li>
                                <li>Fixed bug with druid flight form and 'skip mount' settings causing crashes</li>
                            </ul>
                            <h3>0.99.26 - 2022/09/12</h3>
                            <ul>
                                <li>Fixed bug with spell cooldown tracking between pet and player spellbook.</li>
                                <li>New combat routine condition to track available DK runes - wrath classic.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/mATU3p7.png"></img>
                                </div>
                                <li>Aura combat routine condition allows matching on spell aura name instead of id. Means that you no longer need to specify exact aura Id.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/Q2tx5Bq.png"></img>
                                </div>
                                <li>New 'Delete Items' combat routine action.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/B4KR1Kb.png"></img>
                                </div>
                                <li>Auction house bot now automatically patches TSM addon files. You must set the wow base directory in auction house bot config.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/DvOBhLk.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/60WOxWS.png"></img>
                                </div>
                                
                            </ul>

                            <h3>0.99.23 - 2022/09/07</h3>
                            <ul>
                                
                                <li>Increased blacklist duration on nodes where player does not have required skill from 5 minutes to 15 minutes.</li>
                                <li>Fixed bug where bot would attempt to mount up while dead.</li>
                                <li>Fixed bug with blackspot generation cutting of on tile edges.</li>
                                <h3>Before Fix</h3>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/UNVVNtD.png"></img>
                                </div>
                                <h3>After Fix</h3>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/8yqdKEC.png"></img>
                                </div>

                            </ul>
                            <h3>0.99.22 - 2022/09/06</h3>
                            <ul>
                                <li>Fixed bug where bot under certain conditions would get 'stuck' while returning to town , mounted and in combat and eventually die.</li>
                                <li>Improved path generation for return to town route + now if its unable to generate a path it displays a helpful error message.</li>
                                <li>Fixed issue with non-english clients and buy items functionality</li>
                            </ul>
                            <h3>0.99.20 - 2022/09/02</h3>
                            <ul>
                                <li>Bot now supports gathering mobs that have engineering mats - see gather options.</li>
                                <li>Fixed error with bot not recognising missing profession level or gather node in use.</li>
                                <li>Monitor program now use latest version of .NET 6.</li>
                            </ul>
                            <h3>0.99.16 - 2022/09/01</h3>
                            <ul>
                                <li>Wrath of the litch king classic support.</li>
                            </ul>
                            <h3>0.99.12 - 2022/08/10</h3>
                            <ul>
                                <li>By default bot will now ignore combat while mounted and moving between town and main route. You can disable this using new setting "IgnoreMobsWhileMountedAndMovingBetweenTownAndMainRoute" under base bot config.</li>
                            </ul>
                            <h3>0.99.9 - 2022/08/06</h3>
                            <ul>
                                <li>Improved navigation on steep surfaces - previously would get stuck often trying to run up impossible steep slopes.</li>
                                <li>Fixed bug in gather flying bot for druids where bot would keep shapeshifting out of form during combat.</li>
                                <li>Fixed bug in retail where bot would not detect certain mobs as skinnable</li>
                                <li>Fixed bug with walking on shallow water surfaces and bot would 'spin around'.</li>
                                <li>Fixed bug where bot would crash if player falls off cliff or out of game world. Now will wait until 'Release spirit' is shown, release spirit and if unable to generate a path to the corpse just res at spirit healer.</li>
                                <li>New "UseSmartRangeDetection" option added in combat routine editor for certain action types to improve range detection. This helps especially with mobs that have large hitboxes where the bot would try move too close, forcing it to keep backing away over and over again.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/qbvXx95.png"></img>
                                </div>
                                
                            </ul>
                                
                            <h3>0.99.5 - 2022/07/23</h3>
                            <ul>
                                <li>New options in combat routine action to randomly jump to make the bot look more like a human. Really good for melee classes.</li>
                                <li>New setting to optionally override the notification sound file to use for player alerts. For example if you have different characters or different machines and want to play specific sound for that character.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/95mX9wH.png"></img>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/kP3xXP6.png"></img>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/yrsIt2W.png"></img>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/71JCAfk.png"></img>
                                </div>
                            </ul>
                            <h3>0.99.4 - 2022/07/21</h3>
                            <ul>
                                <li>Bot will now prioritize attacking enemy players instead of their pets in pvp when setting 'PrioritiseFightingOtherPlayersWhenAttacked' is enabled .</li>
                            </ul>
                            <h3>0.99.0 - 2022/07/16</h3>
                            <ul>
                                <li>Gather + Gather Flying bot improvement - bot will now cancel movement to gather node if a blacklist reason is detected during movement to the node.</li>
                            </ul>
                            <h3>0.98.8 - 2022/07/03</h3>
                            <ul>
                                <li><b>Monitor app now stable and ready for use! </b> As always please report any issues to me via discord but we have quite a lot of users now using it with success. Thank you all for the excellent feedback and help improving it over the last few months! </li>
                                <li> Check out the video guides for Monitor app <a href='/guides'>HERE</a></li>
                                <li>Fixed issues with vendor mount routine getting on and back off mount over and over during vendoring sequence - which sometimes caused problems with selling items if bags were full.</li>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.demo_monitor)} />
                                
                            </ul>
                            <h3>0.97.0 - 2022/05/31</h3>
                            <ul>
                                <li>New 'BOT_EVENT_ON_REPEATED_EVADE' event which triggers sound alert when evading mob is detected for 120+ seconds. As with all sound alerts it can be turned on/off through the bot settings.</li>
                                <li>New 'OnlyMailItemIds' option to force bot to only mail certain items. Useful for certain types of profile.</li>
                                <li>New 'TriggerReturnToTownRoutineOnLuaConditionMet' option to make the bot do its return to town routine / vendor mount behavior when the lua condition is met.</li>
                            </ul>
                            <h3>0.96.3 - 2022/05/28</h3>
                            <ul>
                                <li>Evading mob detection fixed to correctly increase blacklist duration after consecutive evades.</li>
                                <li>Improved navigation around steep slopes when player is mounted - previously would have often resulted in bot trying to run straight up steep slope and doing unstuck routine.</li>
                                <li>Fixed bug with item detection in retail client.</li>
                                <li>Flying gather bot can now detect if stuck in a spot which is preventing mounting - for example when stuck under tent will now correctly run out of tent to attempt to mount.</li>
                            </ul>
                            <h3>0.96.0 - 2022/05/22</h3>
                            <ul>
                                <li>New options to play sound alert on bot crash , player died or player stuck events.</li>
                                <li>Evading mob detection fixed to correctly ignore mobs - helps when a evading mob is keeping the player in combat - bot will now attempt to continue its route (does not work in all cases but much improved from original behaviour).</li>
                                <li>Flying gather bot now uses cached navigation routes to reduce cpu usage when flying over areas with challenging geometry.</li>
                                <li>Support for new Shadowlands zones added since release.</li>
                            </ul>
                            <h3>0.95.4 - 2022/05/07</h3>
                            <ul>
                                <li>Flying gather bot will now first try and find reachable ground based nodes before mounting up and flying - This also fixes a bug where bot would land on top of node, fight a mob then mount up flying straight up and back down.</li>
                            </ul>

                            <h3>0.95.3 - 2022/05/04</h3>
                            <ul>
                                <li>Bot will wait less between navigating from waypoint to waypoint. Will be mainly noticeable in gather bots.</li>
                                <li>Fixed bug with aura duration detection where if machine was left on for many days without reboot all aura detection would fail - for example resulting in player spam casting buff on self.</li>
                            </ul>
                            <h3>0.95.1 - 2022/04/18</h3>
                            <ul>
                                <li>Fixed bug with flying gather bot where it would always navigate to the next closest waypoint when following a return to town or res path causing the bot to get stuck at the end of the path.</li>
                            </ul>
                            <h3>0.95.0 - 2022/03/26</h3>
                            <ul>
                                <li>Added support for TBC Classic 2.5.4.</li>
                                <li>Fixed bug when sometimes attaching bot on retail (shadowlands) it would freeze bot and game.</li>
                                <li>Added better threat detection to avoid player getting stuck in combat so often.</li>
                            </ul>
                            <h3>0.94.0 - 2022/02/05</h3>
                            <ul>
                                <li>Gathering of herbs and ores now checks for both matching id OR name in config file. This fixes issues where some nodes have different ids between game versions which would cause bot to ignore certain nodes unless manually added into config with specific ID.</li>
                                <li>Fishing bot can tollerate more network latency - helps prevent 'TIMEOUT REACHED' errors.</li>
                                <li>Bot will finish smooth turning by facing target directly instead of slightly off to side.</li>
                                <li>Bot will detect 'Cannot use while swimming','You can't mount here' when it tries to mount and will disable mounting for 10 seconds. for example in booty bay classic it shows that you can mount but when trying it gives error message.</li>
                                <li>If there have been 3 attempts to mount up in 5 seconds and all have failed for unknown reason, disable mounting for 10 seconds. Helps in cases when bot gets stuck in location which mounting is possible but keep getting dismounted such as inside a low building or under a low tree where the player gets on mount but then is auto dismounted milliseconds later.</li>
                            </ul>
                            <h3>0.93.0 - 2022/01/31</h3>
                            <ul>
                                <li>Fixed pet spell cooldown tracking and pet spell casting. Selecting the 'pet' spellbook type for 'cast spell by id' and 'cast spell by name' actions now works as expected.</li>
                                <li>New combat routine condition 'Threat Status': can be used the check the threat status between source and destination target.</li>
                                <li>Fixed bug with blackspots not loading on bot startup sometimes.</li>
                            </ul>
                            <h3>0.92.0 - 2022/01/27</h3>
                                <ul>
                                    <li>Blackspots now define a height and depth in the UI instead of just defaulting to 5. good if you want to blacklist an entire building or only want to blacklist a single floor in a multi-story house.</li>
                                    <li>Grind bot will no longer pull mobs found within blackspots.</li>
                                    <li>New combat routine condition 'Threat Percentage': can be used to check a source and destination targets threat percent - good for letting pet take high aggro percentage before starting dps rotation for example.</li>

                                </ul>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/knruUCm.png"></img>
                                </div>
                            <h3>0.91.0 - 2022/01/23</h3>
                                <ul>
                                    <li>Reworked cooldown handling so that bot will better detect GCD and when cooldowns are ready - vastly improves performance of combat routines while at the same time reducing CPU usage.</li>
                                    <li>New combat routine condition : 'Spell Cooldown' for tracking spell cooldowns.</li>
                                </ul>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/jMpYY5O.png"></img>
                                </div>
                            <h3>0.90.0 - 2022/01/18</h3>
                                <ul>
                                    <li>New combat routine condition : 'Is Casting' - can be used to check for specific spell id or just if casting anything.</li>
                                    <li>New combat routine condition : 'Is Mounted' - checks if mounted.</li>
                                    <li>New combat routine condition : 'Number of hostile creatures in range' - helpful when for you have pulled multiple targets and need to pop big CDs to survive or to make the bot use AOE.</li>
                                </ul>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/ZUnVArM.png"></img>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/G1zJriG.png"></img>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/Dh8QbjS.png"></img>
                                </div>
                            <h3>0.89.0 - 2022/01/15</h3>
                                <ul>
                                    <li>Fixed bug with cooldown tracking in retail where spells with charges were not being tracked correctly.</li>
                                    <li>New setting : 'PlaySoundOnEventReceived' - If a sound should be played on receiving one of the events defined in PlaySoundOnEventReceivedList</li>
                                    <li>New setting : 'PlaySoundOnEventReceivedLoopTimes' - Number of times to play the notify.wav sound file on event being received.</li>
                                    <li>New setting : 'PlaySoundOnEventReceivedList' - The list of events to play sounds for.</li>
                                    <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/3m5GlJR.png"></img>
                                    </div>
                                    <li>If a player has successfully tapped a gather node recently (mining node) it will keep attempting to gather it even if there is a nearby player / mob meeting the 'IgnoreNodesWithMobsNearby' or 'IgnoreNodesWithPlayersNearby' rules.</li>
                                    <li>Logic for when bot decides it should mount up to reach a destination has been tweaked so that it now must be more than 5 seconds faster via mount before it will use a mount to reach the destination.</li>
                                </ul>
                            <h3>0.88.0 - 2022/01/13</h3>
                                <ul>
                                    <li>Fixed bug with cooldown tracking in retail where spells with charges were not being tracked correctly.</li>
                                    <li>New 'Target Creature Type' combat routine condition which can be used to detect if the target is a player, pet or creature(normal mob).</li>
                                    <li>Bot will now correctly detect when being attacked by another player instead of just stand there are die.</li>
                                    <li>New setting : 'PrioritiseFightingOtherPlayersWhenAttacked' - If the bot should prioritise fighting other players when attacked during combat.</li>
                                </ul>
                            <h3>0.87.2 - 2022/01/11</h3>
                                <ul>
                                    <li>Fixed bug with stop casting causing player pet to be stuck in passive stance.</li>
                                    <li>Added new PetAttack action to combat routine.</li>
                                </ul>
                            <h3>0.87.1 - 2022/01/10</h3>
                                <ul>
                                    <li>Fixed bug with IgnoreNodesWithMobsNearby considering player pets as mobs.</li>
                                </ul>
                            <h3>0.87.0 - 2022/01/09</h3>
                                <ul>
                                    <li>When bot gets stuck during movement it places temporary blackspot at stuck position to help pathing around it.</li>
                                    <li>New options IgnoreNodesWithMobsNearby and IgnoreNodesWithMobsNearbyDistance for avoiding mobs while gathering.</li>
                                    <li>Fixed bug where targets pulled by other players just before combat (stealing) were not correctly being considered invalid in grind bot.</li>
                                    <li>Fixed bug with evading mobs and target swapping constantly.</li>
                                    <li>Fixed bug with target priority system for combat routine not working in classic or classic tbc.</li>
                                    <li>Background changes for bot to detect when a mob/pvp player is in combat with the player. More intelligent at deciding if the target is 'stuck' in combat with the bot.</li>
                                </ul>
                            <h3>0.86.3 - 2022/01/05</h3>
                                <ul>
                                    <li>Fixed bug with 'MoveAwayUsingBackPedal' if target would die before reaching back pedal destination it would keep moving back for 10 seconds - bot will now stop moving back as soon as target is killed.</li>
                                    <li>Fixed bug with 'UseSafeResurrectionPoint' where it would sometimes try and move to unreachable locations (inside walls / trees).</li>
                                </ul>
                            <h3>0.86.2 - 2022/01/04</h3>
                                <ul>
                                    <li>Fixed bug with return to town sometimes getting stuck in a loop when at vendor.</li>
                                    <li>Fixed bug where player would constantly move to mob , stop movement , then start moving again (trying to move closer to mob when it's moving).</li>
                                </ul>
                            <h3>0.86.1 - 2022/01/03</h3>
                                <ul>
                                    <li>Fixed bug with buff sequence not executing when player had no target.</li>
                                    <li>Fixed bug where player would sometimes move forward and cancel casting constantly.</li>
                                    <li>Added new option in combat routine actions for 'MoveAwayUsingBackPedal' - When moving away, if the bot should back pedal instead of using click to move (slower but more like a player)..</li>
                                </ul>
                            <h3>0.86.0 - 2022/01/01</h3>
                            <ul>
                                
                                <li>Bot will cancel casting if a mob is tapped by another player and not yet in combat with it - helps reduce mob stealing.</li>
                                <li>New option 'TryIgnoreCombatWithTappedTargets' for grind bot to ignore targets that the player is in combat with that are tapped and not attacking the player. This is to counteract 'mob stealing' that makes it pretty obvious to other players it's a bot.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/7QrDUjQ.png"></img>
                                </div>
                                <li>New 'BuyFoodDrinkItemList' - separate from buy item list, this is the list of items to buy from the food and drink vendor set in the profile.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/JKWLF8E.png"></img>
                                </div>
                                <li>New option for buying items, 'OnlyBuyItemsPlayerMeetsLevelRequiresFor'</li>
                                <li>New option for buying items, 'ItemIds' - the list of item ids to buy. Unless 'BuyHighestItemlevelItemsFirst' is selected the bot will attempt to buy these items in order listed, stopping when the maximum count is reached. All the items in the list count towards to total count of items for Min and Max count.</li>
                                <li>New option for buying items, 'BuyHighestItemlevelItemsFirst' will attempt to buy the highest item level items from the 'ItemIds' list first - useful for buying highest level food and drink available.</li>
                                <li>New combat routine condition for 'Level' - can use to check player or target level.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/nYiRQrE.png"></img>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/sqhbLzI.png"></img>
                                </div>
                                <li>Bot will now recognise when out of gold and no longer constantly attempt to run back to town to buy items - instead it will blacklist buying the item for 10 minutes.</li>

                                
                            </ul>
                            <h3>0.85.0 - 2021/12/23</h3>
                            <ul>
                                <li>New options for the bot to avoid higher level mobs (adds dynamic blackspots). Good for when pathing back to town or from graveyard.</li>
                                <li>New option 'UseSafeResurrectionPoint'. If the bot should attempt to find a safe place to retrive corpse upon death instead of just reviving directly on top of corpse. Only works if 'ReturnToCorpse' is also enabled.</li>
                                <li>Drastically improved performance of blackspot generation - should see about 9x less cpu usage when the bot is generating dynamic mesh.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/fqTeNGo.png"></img>
                                </div>
                            </ul>
                            <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.demo_higher_mob_level_blackspot_demo)} />
                            <h3>0.84.2 - 2021/12/17</h3>
                            <ul>
                                <li>New config option "UseSmoothFlyingMovement".</li>
                                <li>Fixed bug where bot would not recognise extra bags slots added when user has battle net authenticator added to account.</li>
                            </ul>
                            <h3>0.84.0 - 2021/12/16</h3>
                            <p><b>Happy Holidays, New Year and Botting!</b><br></br>A big thank you to everyone this year who has kept supporting flexbot and kept it alive. Thank you for all the bug reports, feedback and new feature suggestions - please keep them coming! Hope you enjoy this larger than normal update.</p>
                            <ul>
                                <li>Massive overhaul of core bot movement handling.</li>
                                
                                <li>New config option 'UseSmoothMovement' for smoother movement. Helpful in avoiding player reports.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/TNCpGVQ.png"></img>
                                </div>
                                <p>On the left window you can see me following the bot to show smoothness of movement and turning.</p>
                                <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.demo_smooth_ctm_movement)} />
                                <li>New config option 'PullMobsBehindPlayer' for grind bot to disable pulling mobs behind the player. Helpful in avoiding player reports.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/dIFrQfk.png"></img>
                                </div>
                                <li>Fixed bug where sometimes on attaching bot to game it would crash.</li>
                                <li>Fixed bug where sometimes player character would constantly move backwards.</li>
                                <li>Fixed bug where sometimes player character would eat or drink multiple times wasting items.</li>
                                <li>Fixed bug where sometimes player character would keep turning left after facing target.</li>
                            </ul>
                            <h4>Auction House Bot beta is ready for testing! Please message me on discord if you would like to participate in the beta so I can upgrade your key.</h4>
                            <div style={{display:"flex"}} dangerouslySetInnerHTML={videos.createVideoHtml(videos.auction_house_bot_demo)} />
                            <div style={{display:"flex"}}dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_1)} />
                            <div style={{display:"flex"}}dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_2)} />
                            <div style={{display:"flex"}}dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_3)} />
                            <div style={{display:"flex"}}dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_4)} />
                            <h3>0.83.0 - 2021/11/03</h3>
                            <ul>
                                <li>Update to support upcomming release of Classic - Season of Mastery!</li>
                            </ul>

                            <h3>0.82.0 - 2021/09/25</h3>
                            <ul>
                                <li>Fixed issue with events where if bot was left running for more than 24 hours, events would no longer be read until bot was stopped and started again.</li>
                                <li>Added in blacklisting for unreachable lootable mobs to prevent bot from constantly trying to loot mobs. If the bot attempts to interact with the mob more than 20 times it will blacklist it for 5 minutes. This applies to skinning also.</li>
                                <li>The bot now waits for LOOT_CLOSED event before continuing after looting. This aims to fix an issue where users have reported the bot not looting mining nodes because of higher latency or spikes. This also fixes the issue of bot immediately trying to gather node after mining which caused a failed gather attempt and sometimes causing it to exceed the maximum gathering attempts.</li>
                                <li>New option 'Ignore Nodes With Players Nearby Distance' to control how near a player has to be to a node before its considered 'nearby'</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/PhyyD9O.png"></img>
                                </div>
                                <li>Background changes getting ready for release of TSM auction house bot.</li>
                            </ul>
                            <h3>0.81.1 - 2021/08/26</h3>
                            <ul>
                                <li>Beta version of flying gather bot is now available for all users with grind/gather subscription. Includes 2 example profiles in data/profiles/tbc/flying-gather.</li>
                                <li>Fixed issue with deleting items with special characters in which would sometimes result in all items in bags being deleted.</li>
                            </ul>
                            <h3>0.80.0 - 2021/08/15</h3>
                            <ul>
                                <li>By default the subscription selection screen now does not show subscriptions which are expired or in use. Options to show expired/in use subscriptions.</li>
                            </ul>
                            <h3>0.79.0 - 2021/08/07</h3>
                            <ul>
                                <li>New Combat Routine Condition : 'Item Cooldown' - checks if an item is on cooldown.</li>
                                <li>New Combat Routine Action : 'Stop Movement' - stops all movement - - can be useful when used with 'Post Execute Sequence' to chain together actions.</li>
                                <li>New Combat Routine Action : 'Wait' - allows inserting delays between actions - can be useful when used with 'Post Execute Sequence' to chain together actions.</li>
                            </ul>
                            <h3>0.78.3 - 2021/08/01</h3>
                            <ul>
                            <li>New options to mine or gather herbs from mobs.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/vyZ3THE.png"></img>
                                </div>
                            </ul>
                            <h3>0.78.0 - 2021/07/25</h3>
                            <h4>General Changes</h4>
                            <ul>
                            
                            <li>new option to ignore gather nodes with players nearby</li>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/SOvmUGg.png"></img>
                                </div>
                            
                            <li>new option to blacklist mobs in profile editor</li>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/ez6RGHs.png"></img>
                                </div>
                            <li>fix for bot crashing sometimes when running for long periods where log size would be too large</li>
                            <li>fix for copying combat routine actions</li>
                            <li>fixed GetUnitName</li>
                            </ul>

                            <h4>Flying Gather Bot (BETA) Changes</h4>
                            
                            <ul>
                            <li>druid flight form support for classic TBC</li>
                            <li>no longer require return to town path for flying gather bot</li>
                            <li>many small fixes for flying gather bot</li>
                            <li>fix for flying bot so that it no longer attempts to insert random jumps</li>
                            <li>improved bot performance when flying around trying to scan for reachable nodes - fixes some crashes where bot would take too long to find path to node</li>
                            </ul>

                            <h3>0.77.2 - 2021/07/06</h3>
                            <ul>
                                <li>Fixed bug with vendor mount introduced in 0.76.1 where bot would not interact with vendor correctly.</li>
                                <li>Fixed bug where in classic tbc bot would sometimes use lower rank versions of spell incorrectly.</li>
                                <li>Fixed bug with blackspot loading when switching between continents.</li>
                                <li>Fixed bug where displaying overlay sometimes would cause bot to crash if toggled on and off quickly.</li>
                            </ul>
                            <h3>0.77.0 - 2021/07/04</h3>
                            <ul>
                                <li>Added new 'Draw Debug Overlay' option under developer tools which will display paths and navigation related entities such as blackspots. This option is for debug only purposes and uses a lot of additional memory and CPU therefore it is not advised to use for normal bot operation.</li>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_debug_waypoints_video)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_blackspots_guide)} />
                                <li>When exiting the profile editor and waypoint recorder it will prompt the user to confirm they have saved their changes.</li>
                                <li>Fixed issues with scrolling through log when massive list of items is displayed - now is pixel based instead of item based scrolling.</li>
                                <li>Leaving bot running for extended periods (days) will no longer continue to consume more and more memory as the log gets bigger - The log file is now limited to 2000 rows and after that will trim itself.</li>
                                <li>Fixed some performance issues where certain users on lower end machines were seeing lua timeouts during eat and drink routine.</li>
                            </ul>
                            <h3>0.76.1 - 2021/07/02</h3>
                            <ul>
                                <li>Fixed issue where bot would not move into range of vendor correctly - (fixes issues where vendor moves around wide area)</li>
                            </ul>
                            <h3>0.76.0 - 2021/07/01</h3>
                            <ul>
                                <li>Support for 9.1.0</li>
                            </ul>
                            <h3>0.75.0 - 2021/06/20</h3>
                            <ul>
                                <li>Beta version of flying gather bot is available for testing. If you would like access contact me to have your key upgraded. It will be released fully when it has had more testing and I have created a guide video.</li>
                                <li>Updated to v6 of navmesh which fixes some issues with pathing over liquid surfaces like water.</li>
                                <li>Grind bot will no longer attempt to pull mobs which are deep underwater to prevent player from drowning / getting stuck underwater.</li>
                                <li>Fixed various issues with pathing over liquid surfaces - not perfect but a lot better.</li>
                            </ul>
                            <h3>0.74.0 - 2021/06/13</h3>
                            <ul>
                                <li>Reworked mob evasion detection and blacklisting to prevent issue where bot would sometimes blacklist mob which was actually attackable.</li>
                                <li>Grind bot will now correctly ignore targets which get tagged by another player just before combat (mob stealing).</li>
                                <li>Fixed scenario where bot would run between mobs attempting to loot over and over.</li>
                                <li>Fixed issue where bot would sometimes follow other players trying to attack them when they are not even pvp flagged.</li>
                                <li>Fixed issue where bot would sometimes crash on startup attempting to load navigation mesh.</li>
                            </ul>
                            <h3>0.73.0 - 2021/06/06</h3>
                            <ul>
                                <li>Fixed crash when bot attempted to load many meshes concurrently.</li>
                                <li>Fixed issue with profile recorder where it would freeze the bot or wow when enabling path drawing.</li>
                                <li>New "Delete Item Names" option under vendor to delete items from bags every 2 seconds - useful for fishing bot deleting trash items.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/dYsn4ft.png"></img>
                                </div>
                                <li>More background work for flying gather bot support.</li>
                            </ul>
                            <h3>0.72.0 - 2021/06/01</h3>
                            <ul>
                                <li>Fixed bug where sometimes bot would crash completely when attempting to dynamically generate blackspot nav mesh in certain areas where tile exceeded 4MB.</li>
                            </ul>
                            <h3>0.71.0 - 2021/05/28</h3>
                            <ul>
                                <li>A new 'Post Execute Sequence' option to nest combat routine actions. This and the new "Condition Branch" action means that the combat routine builder can now create a fully funcitioning 'behaviour tree'. It also means you don't have to duplicate conditions in every action you need.</li>
                                <li>Changed combat routine condition logging so that by default conditions are not logged unless the setting 'debug conditions' is set against the item.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/ejCW2aq.png"></img>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/w1tDBC1.png"></img>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/VNNpPLx.png"></img>
                                </div>
                            </ul>
                            <h3>0.70.0 - 2021/05/23</h3>
                            <ul>
                                <h4>TBC HYPE?</h4>
                                <li>Support for Classic TBC and Classic ERA versions of game. Time has been added to everyones keys for the duration the bot was unavailable.</li>
                                <li>Overhaul of drawing api - improves performance and fixed memory leak.</li>
                                <li>New combat routine conditions - SpecialPower2, SpecialPower3 ,Druid Rage/Astral Power, Druid Combo points,Druid Energy.</li>
                                <li>Overhaul of combat routine execution - lays the ground work for nested actions sequences to make super expressive combat routines.</li>
                            </ul>
                            <h3>0.64.1 - 2021/05/12</h3>
                            <ul>
                                <li>Fixed bot attach bug with Shadowlands client.</li>
                                <li>Lots of background changes to get ready for flying mounts in TBC and Shadowlands.</li>
                                <li>New option in path recorder to display waypoints in game with overlay.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/LE6PpSm.png"></img>
                                </div>
                                
                            </ul>
                            <h3>0.63.3 - 2021/04/25</h3>
                            <ul>
                                <li>Fixed bug where sometimes bot would crash with 'World Intersect Timeout Exception'. This bug fix should also improve general performance of the bot when there is lag in game or when the host machine slows down.</li>
                            </ul>
                            <h3>0.63.0 - 2021/04/18</h3>
                            <ul>
                                <li>Grind bot bug fixes : If the player is in a party it will recognise mobs which are keeping it in combat that are attacking party members.</li>
                                <li>Gather bot bug fix : "Maximum Failed Gathering Attempts" counts are now correct.</li>
                            </ul>
                            <h3>0.62.0 - 2021/04/15</h3>
                            <ul>
                            <li>Gather Bot : New setting "Ignore All Combat" : if the bot should completely ignore all combat - just run the route and gather nodes.</li>
                                <li>Base Bot improvement - New Mount Method "Cat Form". Uses druid cat form instead of mount or travel form.</li>                                
                                <li>Base Bot improvement - New setting "Maximum Failed Gathering Attempts" : The maximum number of failed gathering attempts before the bot should move on. 'failed' meaning for example the player is interrupted while attempting to mine a node. default is 4 if left blank.</li>
                                <li>Base Bot improvement - New setting  "Failed Gathering Blacklist Duration In Seconds" : When the maximum number of failed gathering attempts is reached, how long the node should be blacklisted for in seconds. Default is 120 seconds if left blank.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/qmJW6Jz.png"></img>
                                </div>
                            </ul>
                            <h3>0.60.2 - 2021/04/07</h3>
                            <ul>
                                <li>Grind bot : New settings for delaying looting. Useful for when farming small spot with fast respawning mobs.</li>
                                <li>Fixed bug where sometimes bot would not correctly recognise when the player knows a spell.</li>
                                <li>Bot will now refresh its known item cache whenever the player loots a new item - prevents issues like mages constantly conjuring food when bot is first started.</li>
                            </ul>
                            <h3>0.59.0 - 2021/03/29</h3>
                            <ul>
                                <li>Fishing Bot : New option to logout on bags full.</li>
                                <li>Fishing Bot : New option to stop bot on bags full.</li>
                            </ul>
                            <h3>0.58.0 - 2021/03/27</h3>
                            <ul>
                                <li>Combat Routine : Added support for ground targeted abilities such as warlocks rain of fire.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/gy8P0Bw.png"></img>
                                    </div>
                                
                            </ul>
                            <h3>0.57.0 - 2021/03/24</h3>
                            <ul>
                                <li>New 'stack count' conditions added to Aura Condition in combat routine. For use with auras which have stacks - e.g. cast spell when number of stacks greater than 10</li>
                                <li>Fishing bot will now stop when player is dead instead of spam attempting to cast fishing while dead.</li>
                            </ul>
                            <h3>0.56.0 - 2021/03/15</h3>
                            <ul>
                                <li>Added support for patch 9.0.5</li>
                                <li>Adding 4 days to all keys which were active at the time of the patch landing.</li>
                            </ul>
                            <h3>0.55.0 - 2021/02/25</h3>
                            <ul>
                                <li>New random jump option for use with grind and gather bot. Bot will jump randomly between waypoints when it is safe to do so.</li>
                                <li>Introduced new 'TargetMode' and 'TargetPriority' settings in combat routines. This is the start of a set of smaller updates to add full support for party based combat.<br/>Along these lines also added logic for combat bot to support following a configured party member. In future will add a 'AssistTarget' option so that bot will kill the 'Tanks' target for example.</li>
                                <li>Better detection for eating/drinking - mainly to fix some issues in classic.</li>
                            </ul>
                            <h3>0.54.0 - 2021/02/13</h3>
                            <ul>
                                <li>Support for adding blackspots to profiles. The bot will avoid moving in the area as much as possible and find a route around the area to reach its destination.</li>
                            </ul>
                            <h3>0.53.0 - 2021/02/03</h3>
                            <ul>
                                <li>Add small randomization to paths so that every time bot starts the path the bot takes is very slightly different (small enough that it does not get stuck).</li>
                                <li>Fixed bug when mailbox position was not set bot would ignore use of mailbox toy.</li>
                                <li>Further background work to get dynamic mesh loading working.</li>
                            </ul>
                            <h3>0.52.0 - 2021/01/24</h3>
                            <ul>
                                <li>Major rework to navigation loading process to support upcoming patch for dynamic obstacles (blackspots and elite mob evasion).</li>
                                <li>Fixed bug where sometimes bot would fail to load on older machines without certain windows patches.</li>
                                <li>Added support for <a href="https://www.wowhead.com/item=156833/katys-stampwhistle">Katy's Stampwhistle Toy</a> - lets bot use her as a mailbox instead of returning to town. Works with fishing, grind and gather bots.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/oJI86JX.png"></img>
                                    </div>
                                
                            </ul>
                            <h3>0.51.1 - 2021/01/16</h3>
                            <ul>
                                <li>New bypass for detection methods that blizzard added on 2020/01/8. <b>All subscription keys were frozen while bot was unavailable. Also added an additional 4 days to all subscription keys which were valid when this detection occured.</b></li>
                                <p>Over the last 7 days I have created a total of 8 new battle.net accounts to do testing. 4 running the old version of bot (0.50.6) and 4 running the new version (0.51.1). All 4 accounts running 0.50.6 got ban within 24 hours.<br></br>
                                At the same time on another machine I ran the other 4 accounts with the new version without any bans. These 4 accounts now have been running for 5 days - still no ban.<br></br>
                                To confirm that bots running new version were not just missing these ban waves (lucky) I made sure to have both the old and new version of bot running at same time on different machines. As expected, accounts running old version were banned after 4 hours and new version kept running.
                                </p>
                                
                                <p><b>Be aware that I have only proven this new version is undetected for last 5 days. As always use at your own risk. Don't use on an account you are scared to lose. I hate reading stories of people who invest a lot of their time on their account getting banned :(</b></p>
                            </ul>
                            <h3>0.50.6 - 2020/12/31</h3>
                            <ul>
                                <li>Fixed bug with new `ReturnToCorpse` feature where bot would crash if player died while returning to town.</li>
                            </ul>
                            <h3>0.50.5 - 2020/12/29</h3>
                            <ul>
                                <li>Bot no longer sends any key presses to wow client.To prevent possible future detections if blizzard decides to ban macro sending tools like razer synapse etc.</li>
                            </ul>
                            <h3>0.50.1 - 2020/12/21</h3>
                            <ul>
                                <li>Fixed some instances where game would crash when loading mob information.</li>
                            </ul>
                            <h3>0.50.0 - 2020/12/20</h3>
                            <ul>
                                <p>Happy holidays everyone! Many users have reported crashes with an error along the lines of ''ACCESS_VIOLATION - The memory could not be 'read''. Now that the classic wow client is working again I will have time to look into resolving this bug. Thank you for your patience.</p>
                                <li>Bot is working with classic wow client again! Will be adding time to keys for all those classic bot users for the duration the bot has been unavailable. If I don't add you time back please ping me on Discord and I will be happy to add.</li>
                                <li>Large overhaul to way lua is executed to bypass protections added by blizzard in latest classic wow patch.</li>
                                <li>Added new ReturnToCorpse option (Experimental) in bot config so that when player dies bot will attempt to return to corpse instead of using spirit healer.</li>
                                <li>Fixed bug in retail where bot would attempt to skin mob which was already 'tapped' by another player.</li>
                                <li>Fixed bug (most cases) where player would sometimes walk backwards after dismounting or finishing mining/herb.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/3rkSWv5.png"></img>
                                    </div>
                            </ul>
                            <h3>0.48.2 - 2020/11/28</h3>
                            <ul>
                                <li>Various bug fixes for gather bot.</li>
                                <li>Bot log is now dumped to file on crash/death for debugging purposes.</li>
                            </ul>
                            <h3>0.48.0 - 2020/11/23</h3>
                            <h4>Shadowlands launch!</h4>
                            <p>Big thank you to everyone who has been supporting development of this project. <br/> 
                            Now that the combat routines and grind bot core is mostly compelted I have been able to commit time to adding some long awaited gather bot features and improvements. 
                            <br/> I have the week off work to both enjoy shadowlands launch and offer support for the bot as I know that early expansion is the big gold maker!</p>
                            <ul>
                                <li>New 'Blackspot' feature for gather bot profiles to skip nodes which are in areas where bot gets stuck or in large packs of enemies.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/kGrVc60.png"></img>
                                    </div>
                                <div dangerouslySetInnerHTML={guide_gather_blackspots_1_video()} />
                                <li>Added new setting to gather bot 'Ignore Mobs Along Route'. This will ignore mobs which attack player while moving between gather nodes.</li>
                                <li>Added new setting to gather bot 'Completely Ignore Mobs While Mounted'. Tells bot to completely ignore mobs while mounted. Useful when used with Sky Golem or Druid Travel form and gathering herbs.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/gY5r0xP.png"></img>
                                    </div>
                                <li>Bot no longer uses any types of key press injection.</li>
                                <li>Gather bot will now correctly stop at or before reaching node instead of always attempting to go directly ontop of node.</li>
                                <li>Added shadowlands main map to maplist.json to get ready for shadowlands nav mesh upload which will happen tomorrow.</li>
                                <li>Fixed bug in retail where bot would sometimes attempt to gather a second time after successfully gathering node.</li>
                                <li>If 'flying' when attempting to gather a node bot will now descend till it is safe to gather. This is to fix issue when using flying mount, sometimes bot would start flying between waypoints causing bot to get stuck trying to gather mid air.</li>
                                <li>Added shadowlands herbs and ores to WellKnownStatics.json so that they appear as new options under configuration.</li>
                                <li>Druid travel form now checks for any of ShapeshiftForm.EpicFlightForm , ShapeshiftForm.FlightForm , ShapeshiftForm.Aqua or ShapeshiftForm.Travel</li>
                            </ul>
                            
                            <h3>0.47.0 - 2020/11/14</h3>
                            <ul>
                                <li>Blacklisting of underwater gather nodes and loot.</li>
                                <li>Blacklisting of unreachable mobs - where ground navigation via the nav mesh is not possible.</li>
                                <li>Blacklisting of evading mobs.</li>
                                <li>Bot will no longer error sometimes when mob it is trying to kill is flying just above ground.</li>
                            </ul>
                            <h3>0.46.0 - 2020/11/08</h3>
                            <ul>
                                <li>Added Druid Travel Form Support - new option under mount method "Travel Form"</li>
                                <li>Fixed issue where bot would sometimes spam click along route incorrectly.</li>
                            </ul>
                            <h3>0.45.0 - 2020/10/30</h3>
                            <ul>
                                <li>Improved "Is Player In Combat" checks.</li>
                                <li>Fixed bug where bot would ignore targets attacking pet while player not in combat.</li>
                                <li>Bot will now recognise when stuck in combat. If player is in combat for longer than 20 seconds with no enemies attacking it, bot will ignore combat and continue profile.</li>
                                <li>Fixed bug where sometimes player would wait for pet combat to end before performing next action.</li>
                                <li>Fixed bug where special targets appear dead but bot considered them a valid target to attack.</li>
                            </ul>
                            <h3>0.44 - 2020/10/25</h3>
                            <ul>
                                <li>Added support for patch 9.0.1!</li>
                            </ul>
                            <h3>0.43.4 - 2020/10/04</h3>
                            <ul>
                                <li>New Combat Routine Condition - Has Weapon Enchant.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/wdSTdzc.png"></img>
                                    </div>
                                <li>New "Is Channeled" property for spells to fix bug where cast was being cancelled mid way through channel.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/DA8sa2v.png"></img>
                                    </div>

                            </ul>
                            <h3>0.43.1 - 2020/09/26</h3>
                            <ul>
                                <li>Bot is now aware of player and enemy height - fixes some line of sight issues.</li>
                                <li>Improved anti afk method - fixes issue where sometimes player would be logged out when waiting at graveyard for too long.</li>
                                <li>Many background changes to prepare for shadowlands launch.</li>
                                <li>Background bot changes required for relogger to function correctly.</li>
                            </ul>
                            <h3>0.43.0 - 2020/08/25</h3>
                            <ul>
                                <li>Added HasCastTime option to "Use Item" action so that bot will wait for casting to complete when the item being used has cast time - fixes issues in classic where "use bandage" cast was being interrupted</li>
                                <li>Improved bot brain to now be aware of threat for mobs against both the player and his/her pets</li>
                                <li>Fixed bug in classic client where game would sometimes crash when skinning mobs due to "process read memory error"</li>
                                <li>Fixed bug where bot would not target mobs it had aggro with if the mob was not targeting the player - e.g. when rooted in ice nova.</li>
                            </ul>
                            <h3>0.42.0 - 2020/06/28</h3>                            
                            <ul>
                                <li>Will now only loot when it is safe to do so , instead of moving into packs of mobs to loot.</li>
                                <li>Gather bot - fixed bug where sometimes bot goes in loop when trying to reach node.</li>
                                <li>Now detects special cases when unable to mount and waits couple of seconds to try and mount again or when zone changes - e.g. booty bay or cave entrances.</li>
                            </ul>
                            <h3>0.41.1 - 2020/06/21</h3>                            
                            <ul>
                                <li>Minor gather bot improvements to detection of node gather completion - results in faster gathering behaviour - less bot like.</li>
                            </ul>
                            <h3>0.41.0 - 2020/06/20</h3>                            
                            <ul>
                                <li>Performance improvements which should reduce time spent between movements. Makes bot look less bot like.</li>
                                <li>Fixed bug where if no mailbox or vendor(s) were set in profile and bot was configured to use them it would download entire world mesh.</li>
                                <li>Stopping bot now stops mesh download process.</li>
                            </ul>
                            <h3>0.40.0 - 2020/06/06</h3>                            
                            <ul>
                                <li>Added additional logging for when bot crashes or player dies.</li>
                                <li>Configure screenshot and event log location.</li>
                                <li>Fixed bug where player would interrupt cast when in game latency is high.</li>
                                <li>Fixed bug where bot would sometimes crash when using spirit healer.</li>
                                <li>Improved retry logic for when performing bot heartbeat to make bot stop less from "Heartbeat failed" type errors.</li>
                                <li>Better spell casting detection for when player is moving straight after casting spell.</li>
                                <li>Bot no longer uses higher CPU while waiting for cast.</li>
                            </ul>
                            <h3>0.39.3 - 2020/05/27</h3>                            
                            <ul>
                                <li>When target dies, swap target to lowest % HP creature in combat instead of closest.</li>
                            </ul>
                            <h3>0.39.1 - 2020/05/26</h3>                            
                            <ul>
                                <li>Fixed bug where when sometimes attaching bot to game click to move would bug out.</li>
                            </ul>
                            <h3>0.39.0 - 2020/05/25</h3>                            
                            <ul>
                                <li>Grind bot will now only search for targets which are in range of points along the path. This prevents bot from going too far off from the main route and getting stuck / moving into elite mob packs not intended.</li>
                                <li>Fixes to targeting logic to be more intelligent when entering / leaving combat. Will swap to target mobs which are pulled if the current target is not in combat.</li>
                                <li>Bot will now choose target with lowest hp attacking it after killing mob.</li>
                                <li>Background work continues for adding in support for blacklisting mobs which are evading.</li>
                                <li>Fixed bug where bot would sometimes crash when performing unstuck routine.</li>
                                <li>Fixed bug where bot would sometimes cancel channeled casts.</li>
                                <li>Improved performance of pathing long distances.</li>
                            </ul>
                            <h3>0.38.0 - 2020/05/23</h3>                            
                            <ul>
                                <li>Fixed bug in BFA where aura durations where not being checked correctly.</li>
                                <li>After killing mob, looting or gathering bot will move to closest waypoint instead of going all way back where first started activity.</li>
                                <li>Vastly improved unstuck behaviour to now attempt moving back and side to side in order to try and unstuck.</li>
                                <li>Mob and gather node blacklisting when unable to generate path to destination. This fixes bug where bot would previously crash when unable to reach destination.</li>
                                <li>Automatic check for if click to move is enabled before starting bot.</li>
                                <li>Added Combat Routine : BFA-Rogue-Assassination-V2.0.0 as I have been playing some BFA myself recently</li>
                            </ul>
                            <h3>0.37.0 - 2020/05/17</h3>
                            <p>This patch brings a large rework of the navigation mesh building process that is used on the server along with major improvements to the navigation the bot uses. It's been a long month :) A lot of work has gone into this one. Hope to spend the next month on features and fixes the community have been requesting. Thanks for your support as always. Keep safe!</p>
                            <ul>
                                <li>Fixed bug where bot would sometimes move between two points over and over again.</li>
                                <li>Fixed many issues where bot would run straight into wall / slope when traveling long distances to reach destination. Example human starting zone to Stormwind AH</li>
                                <li>Reduced navigation mesh file size resulting in faster download speeds. (~3x faster).</li>
                                <li>Reduced RAM usage when loading large amounts of navigation data. (~5x less memory used for navigation data)</li>
                            </ul>
                            <h3>0.36.0 - 2020/04/18</h3>
                            <ul>
                                <li>Improved Intelligence : Bot will now loot or skin closest mob instead of first looting all mobs in area before skinning</li>
                                <li>Bug fix : Bot will no longer try and skin tapped mob after skinning success</li>
                                <li>Bug fix : Bot will no longer try to eat or drink when swimming</li>
                                <li>Bug fix : Bot will no longer try to mount up when swimming</li>
                                <li>Navigation Improvement : Bot will attempt to unstuck itself when trying to move to same CTM destination multiple times. The unstuck method is crude for the time being - work continues on fixing navigation so that bot does not navigate between two points over and over.</li>
                                <li>Navigation Improvement : Bot will now move to closest waypoint after combat or looting instead of going all way back to original Waypoint</li>
                                <li>Added helpful "last config" popup for after attaching so picking last used config is simple</li>
                                <li>Added Classic Hunter combat routine for 1-40</li>
                                <li>Fix combat status combat condition to use target type selected instead of default of target</li>
                                <li>New event management system to allow bot access to in game events. In future this will be used to allow actions to be performed based on certain events taking place e.g. on player whisper play sound/reply
                                    <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/iIYyqxq.png"></img>
                                    </div>
                                </li>
                            </ul>
                            <h3>0.34.0 - 2020/03/22</h3>
                            <ul>
                                <li>New avoidance behavior settings have been introduced on "Pre-Pull" and "Combat" sequence items to allow player to move away from target after performing an action.<b/>
                                    <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/iWTSOQN.png"></img>
                                    </div>
                                </li>
                                <li>New "Auto Shoot" action type has been added.</li>
                                <li>There is now a separate food + drink vendor which can be set in both profile and vendor mount configuration.</li>
                                <li>Added support for vendors which have dialog options to access them.</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ maxWidth: "800px" }} src="https://imgur.com/4I1HAzo.png"></img>
                                </div>


                            </ul>
                            <h3>0.33.2 - 2020/03/15</h3>
                            <ul>
                                <li>Fixed bug for classic wow when druid is in shapeshift form he is unable to interact with vendor. Now bot will cancel shapeshift before interact.</li>
                                <li> </li>
                            </ul>
                            <h3>0.33.0 - 2020/03/15</h3>
                            <p>Added new buy items feature. Allows user to configure set of items to buy.</p>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                            <img style={{ width: "100%", maxWidth: "1200" }} src="https://i.imgur.com/HjG8PSv.png"></img>
                            </div>
                            
                            <h3>0.32.1 - 2020/03/11</h3>
                            <ul>
                                <li>Fixed bug where when returning to town bot would sometimes download all mesh files for continent when a return to town path was not provided.</li>
                            </ul>
                            <h3>0.32.0 - 2020/03/06</h3>
                            <p>Fishing bot now has feature to execute a user provided lua script e.g. press action button 1 every 10 seconds to open clams or a chest.</p>
                            <p>If you get an error with "VCRUNTIME140_1.dll missing" some users have reported they needed to install <a href="https://aka.ms/vs/16/release/vc_redist.x64.exe">vc_redist.x64.exe</a> to make the bot attach correctly.</p>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                            <img style={{ width: "100%", maxWidth: "1200" }} src="https://i.imgur.com/UjXlTwf.png"></img>
                            </div>
                            <h3>0.31.0 - 2020/02/28</h3>
                            <p>Introducing a new bot type 'Stationary Grind'. For farming spots like shown in this video : <a href="https://www.youtube.com/watch?v=btz25X6Pk4g">https://www.youtube.com/watch?v=btz25X6Pk4g</a>. The bot includes settings to force player to loot while in combat and disable movement when looting.</p>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                            <img style={{ width: "100%", maxWidth: "1200" }} src="https://i.imgur.com/TAtYl0W.png"></img>
                            </div>
                            <ul>
                            <li>New 'Number of bag slots' combat routine condition. Perfect for when you want to perform action only when bags are not full / full (e.g. remove monk statue to allow dropping out of combat). Example combat routine for monk included 'BFA-Monk-Stationary-Farm.json' </li>
                            <li>Mailbox position is now optional when configuring a profile.</li>    
                            <li>Sell Vendor is now optional when configuring a profile.</li>    
                            <li>Buy Vendor is now optional when configuring a profile.</li>    
                            <li>Repair Vendor is now optional when configuring a profile.</li>
                            <li>New profile type of 'Stationary' to be used in conjuction with the new 'Stationary Grind' bot. Define a standing position where the player should wait for mobs to spawn and optionally a safe vendor mount position where the player will move to when bags are full.</li>      
                            
                            </ul>
                            <h3>0.29.2 - 2020/02/02</h3>
                            <p>I have put in fixes for new detection vectors added by Blizzard after the BFA 8.3.0 patch. Thank you all for your patience during this time.</p>
                            <p>All users have had 8 days added to their subscription keys.</p>
                            <h3>0.28.0 - 2020/01/18</h3>
                            <p>added support for new BFA 8.3.0 patch.</p>
                            <h3>0.27.0 - 2020/01/11</h3>
                            <p>Happy new year!</p>
                            <p>Over the last few weeks I have pushed out updates to the navmesh which mean the bot can now navigate through shallow water without crashing.</p>
                            <p>I have also included a bunch more grinding profiles for classic and some more combat routine examples.</p>
                            <ul>
                                <li>Overhaul of surface water navigation means bot should no longer crash when walking through shallow water.</li>
                                <li>Path recording now has a "Fix Underwater Waypoints" option which when used moves waypoints which are underwater to water surface.</li>
                                <li>There is now a file picker for selecting the combat routine instead of having to enter it manually (finally!).</li>
                                <li>Fixed bug where "UseWandAction" was broken on non English clients.</li>
                                <li>Fixed bug where if game FPS dropped for long period bot would crash.</li>
                                <li>Fixed bug where CPU usage would spike when a player is using a pet in some scenarios.</li>
                                <li>Reduced overall bot CPU usage by up to 20% (Improvements to object manager logic)</li>
                                <li>Fixed issue where sometimes mob is detected as out of line of sight when close to obstacle.</li>
                                <li>Added authentication retry so bot will continue to work even if auth servers go down for up to 5 minutes.</li>
                            </ul>
                            <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                            <img style={{ width: "100%", maxWidth: "800px" }} src="https://i.imgur.com/OonX7HR.png"></img>
                            </div>
                            
                            <h3>0.26.1 - 2019/12/22</h3>
                            <ul>
                                <li>Improvement : Bot now follows moving targets when moving into melee range.</li>
                            </ul>
                            <h3>0.26.0 - 2019/12/19</h3>
                            <ul>
                                <li>Feature : Added new "Combat Status Condition" to combat routine profile creator to only execute action when in/out of combat.</li>
                                <li>Feature : Added "Range Condition" to combat routine profile creator to only execute action when range specified is met (e.g. Warrior Charge only when > 8 yards).</li>
                                <li>Feature : Added "Auto Attack" action type.</li>
                                <li>Improvement : added more verbose combat routine logging + reduced lag when large numbers of log entries where displayed on screen.</li>
                                <li>Improvement : When starting the bot away from the 'MainRoute' the bot will now calculate the safest path it can to reach the 'MainRoute' following any paths if possible (Res, ReturnToTown .etc).</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                    <img style={{ width: "100%", maxWidth: "800px" }} src="https://i.imgur.com/9Knl2cA.png"></img>
                                    <img style={{ width: "50%", maxWidth: "800px" }} src="https://i.imgur.com/bgN98WJ.png"></img>
                                    <img style={{ width: "50%", maxWidth: "800px" }} src="https://i.imgur.com/6e7KmkY.png"></img>
                                    <img style={{ width: "50%", maxWidth: "800px" }} src="https://i.imgur.com/0MM3b7W.png"></img>
                                    <img style={{ width: "50%", maxWidth: "800px" }} src="https://i.imgur.com/V6tRo6Z.png"></img>

                                </div>
                            </ul>
                            <h3>0.25.0 - 2019/12/07</h3>
                            <ul>
                                <li>Feature : Ability to specify what ores and herbs to gather. list can be added to by editing 'WellKnownStatics.json'</li>
                                <img style={{ width: "100%", maxWidth: "800px" }} src="/resources/ListOfOresAndHerbs.PNG" /> <br />
                                <li>Improvement : Bot now correctly stops moving along waypoint graph when it detects mobs that can be skinned.</li>
                                <li>Bug fix : Spell cooldown is now checked as part of combat routine conditions.</li>

                            </ul>
                            <h3>0.24.10 - 2019/11/26</h3>
                            <ul>
                                <li>Improvement : Smoother CTM movement aiming to reduce stop/start movement.</li>
                                <li>Bug fix : fixed issue where bot would sometimes ignore attacking enemies when moving to next navigation waypoint.</li>
                            </ul>
                            <h3>0.24.8 - 2019/11/16</h3>
                            <ul>
                                <li>Bug fix : fixed bot crashing when mining/herbing in classic client.</li>
                                <li>Bug fix : fixed bug where player would sometimes run ontop of target when trying to use spell from range.</li>
                            </ul>
                            <h3>0.24.7 - 2019/11/16</h3>
                            <ul>
                                <li>Bug fix : Fixed bug where on older versions of .net framework installed some users fail to log in.</li>
                            </ul>
                            <h3>0.24.5 - 2019/11/15</h3>
                            <p>Background work to improve diagnostic logging and performance of application when downloading nav mesh files.</p>
                            <h3>0.24.3 - 2019/11/10</h3>
                            <ul>
                                <li>Bot Feature : New option to stop bot on death.</li>
                                <li>Bug fix : Fixed bug with BFA mesh loading when changing zones.</li>
                                <li>Bug fix : Program sometimes remained running even after all windows closed.</li>
                                <li>Improvement : Option to remember login details.</li>
                                <li>Improvement : More helpful error message when incorrect action button text is set.</li>
                                <li>Improvement : More verbose error message for navigation mesh failure.</li>
                            </ul>
                            <h3>0.24.2 - 2019/11/06</h3>
                            <p>Fixed bug where bot would sometimes automatically dismount when using a spell to mount up.</p>
                            <h3>0.24.1 - 2019/11/04</h3>
                            <p>Fixed bug where bot did not detect mesh files had been downloaded already.</p>
                            <h3>0.24.0 - 2019/11/04</h3>
                            <p>Major rework of navigation mesh generation pipeline so that BFA and Classic now have their own specific navigation meshes.</p>
                            <h3>0.23.6 - 2019/10/11</h3>
                            <p>A few small updates while work continues on nav mesh overhaul.</p>
                            <ul>

                                <li>Removed UseCustomCombatRoutine option as everything will now be based on custom combat routine.</li>
                                <li>Update some descriptions of settings.</li>
                                <li>More verbose logging for CheckSpellKnown.</li>
                                <li>Few minor UI tweaks.</li>
                                <li>Add debug option for conditions.</li>

                            </ul>
                            <img style={{ width: "100%", maxWidth: "800px" }} src="/resources/VerboseLogging23.6.PNG" /> <br />
                            <h3>0.23.5 - 2019/10/08</h3>
                            <ul>
                                <li>added more verbose logging to eating and drinking + only use items from bags not anything within container</li>
                            </ul>
                            <h3>0.23.4 - 2019/10/06</h3>
                            <ul>
                                <li>possible bug fix for when new 8.2.5 object manager would sometimes cause wow to crash with failed to 'read' memory error.</li>
                            </ul>
                            <h3>0.23.2 - 2019/10/06</h3>
                            <ul>
                                <li>Removed AutoConfirmBindOnPickupLoot - causing issues for some users. Suggested alternative is to install addons instead (https://www.curseforge.com/wow/addons/leatrix-plus-classic)</li>
                            </ul>
                            <h3>0.23.1 - 2019/10/03</h3>
                            <ul>
                                <li>New Issue tracker to see bot development progress and what is being worked on. <a href="https://trello.com/b/8jo9c4P5/issue-tracker">Issue Tracker</a></li>
                                <li><b>Breaking change to combat routines </b> : Power Condition (mana,rage etc) is now percentage based instead of a specific value.</li>
                                <li>Added AutoConfirmBindOnPickupLoot setting for bot to automatically confirm bind on pickup items when looting.</li>
                                <li>Improved Combat Routine Editor to display disabled actions with "(Disabled)" label.</li>
                                <li>Improved logging to be more verbose when navigation fails to generate path.</li>
                                <li>Fixed bug where bot would drink or eat even when conditions were not met.</li>
                            </ul>
                            <h3>0.23.0 - 2019/09/28</h3>
                            <ul>
                                <li>Support for 8.2.5. All users subscription keys will be given 4 additional days because of the down time.</li>
                                <li>Bunch of core changes to bot logic to consume less CPU when running between waypoints.</li>
                                <li>Added new Mount Method option to support classic mounts which are items from bags.</li>
                            </ul>
                            <h3>0.22.1 - 2019/09/25</h3>
                            <ul>
                                <li>Fix classic fishing bot bug where lure is not applied correctly.</li>
                            </ul>
                            <h3>0.22.0 - 2019/09/23</h3>
                            <ul>
                                <li>Major overhaul to unit aura checks which drastically improves performance of combat and grind bot. Aura checks were sometimes taking multiple seconds are now taking &lt; 5 ms when spell information has been cached.</li>
                                <li>Improved Combat Routine compatibility with localized wow clients (no longer crashing on special characters).</li>
                                <li>Fixed bug where if there were duplicate item ids specified in drinkItemIds or foodItemIds bot would crash.</li>
                            </ul>
                            <h3>0.21.5 - 2019/09/21</h3>
                            <ul>
                                <li>Fixed bug with Special Power(Combo points) offset being read incorrectly.</li>
                                <li>Bot now able to handle special characters for localized wow clients (UTF8 encoding support).</li>
                            </ul>
                            <h3>0.21.4 - 2019/09/20</h3>
                            <ul>
                                <li>New fishing bot config option FishingLureItemId can be found under the fishing bot settings. Bot will check if a lure is applied to the fishing rod, if not it will use the item id from bags.</li>
                            </ul>
                            <h3>0.21.3 Combat + Grind Beta! - 2019/09/20</h3>

                            <ul>
                                <li>Beta versions of the Combat and Grind bot are now available to users with a active fishing bot subscriptions.</li>
                                <li>Please note: The navigation mesh is based on the retail client and not classic so you might find the bot getting stuck on terrain or crashing when moving through areas where the game world has changed since Classic. Navigation meshes for Classic will be generated at some point when I get the time but this has not caused me any issues (yet) while running bots in classic.</li>
                            </ul>
                            <p>
                                Below is some documentation and video guides for people getting started. <br />
                                <a href="https://github.com/mousewookiesoftware/BotDocumentation">Bot Documentation (Combat Routine + Grind Bot Profile)</a>
                                <div dangerouslySetInnerHTML={demo1_video()} />
                                <div dangerouslySetInnerHTML={demo2_video()} />
                                <div dangerouslySetInnerHTML={demo3_video()} />

                            </p>

                        </div>
                        <div>
                            <h3>0.20.3 - 2019/09/01</h3>
                            <p>Fix for "Expired Token" bug - no longer relies on system clock.</p>
                            <h3>0.20.2 - 2019/09/01</h3>
                            <p>Added additional logging to help diagnostics (Token Expired bug).</p>
                            <h3>0.20.1 - 2019/08/28</h3>
                            <p>Fixes bug which caused error when launching the fishing bot with any class but warlock...</p>
                            <h3>0.20.0 - 2019/08/28</h3>
                            <ul>
                                <li>Fishing bot now supports Classic Wow Client!</li>
                                <li>
                                    Added some new configuration options getting ready for release of combat/grind bot.<br />
                                    <b>The config file format has changed slightly so you will need to update the config path to defaultBotConfig.v2.0.0.json</b> .
                            </li>
                                <li>
                                    Work in progress - if you're interested I have written some docs for combat rotation creators <a href="https://github.com/mousewookiesoftware/BotDocumentation">Documentation</a>.
                                    Will be looking to release a video along side these docs to help combat rotation developers in the future.
                            </li>
                            </ul>
                            <p>
                                <img style={{ width: "100%", maxWidth: "800px" }} src="/resources/UpdatedConfig0.20.0.PNG" /> <br />


                            </p>
                            <h3>0.19.0 - 2019/08/14</h3>
                            <p>Big update getting ready for grind + gather bot beta and Wow Classic launch.<br />
                                Grind + Gather bot beta will be available soon for all users with active fishing bot subscriptions. <br />
                                Planning to take a week off work during Wow classic launch to get fishing bot update released. Expect to see an update 2-3 days after launch! <br />
                                Grind + Gather bot will take a while to be working for classic because it requires all new world navigation data to be generated and built.</p>

                            <h3>0.18.8 - 2019/08/03</h3>
                            <p>Flexbot.App : The fishing bot now lets users select a vendor mount to use when bags are full.</p>
                            <div dangerouslySetInnerHTML={demo0_18_8_video()} />
                            <h3>0.17.15 - 2019/07/25</h3>
                            <p>Flexbot.App : Minor UI improvements</p>
                            <p>Flexbot.Web : Now possible to purchase subscription keys through the website - still in early testing stages so please if you have any issues contact me on discord mousewookie#8631 or by email <a href={"mailto:mousewookiesoftware@gmail.com"}>mousewookiesoftware@gmail.com</a>. </p>
                            <h3>0.17.14 - 2019/07/17</h3>
                            <p>Fixed issue with "RandomFishingDelay" sometimes not catching fish because of waiting too long.</p>
                            <h3>0.17.13 - 2019/07/16</h3>
                            <p>Fixed issue with "RandomFishingDelay" sometimes causing wow to crash with "the memory could not be 'read'" error.</p>
                            <h3>0.17.12 - 2019/07/15</h3>
                            <p>Improved logging and diagnostics + minor change to "interact with object" logic to reduce chance of "the memory could not be 'read' " error being thrown.</p>
                            <h3>0.17.11 - 2019/07/13</h3>
                            <p>New "RandomFishingDelay" toggle for fishing bot. Inserts random 0-2 second delay before catching fish after bobber splash.</p>
                            <img style={{ width: "100%", maxWidth: "800px" }} src="/resources/RandomFishingDelay0.17.11.PNG" />
                        </div>
                    </div>}
                {!this.props.loggedIn &&
                    <p>Log in to see patch notes. <a href="/">Log in</a></p>
                }



            </div>
        );
    }
}

export default PatchNotes;